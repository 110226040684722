<template>
  <div class="contact-info-section">
    <h2 id="contact">Contact <span class="underline-heading--teal">Us</span></h2>
    <div class="contact-info-container">
      <div class="contact-info">
        <img src="../assets/icons/phone.svg"  alt="mobile phone icon">
        <div class="contact-text">
          <p>Call us</p>
          <p>Mon-Fri from 8am to 5pm</p>
          <a href="tel:+447898 000532">07898 000532</a>
        </div>
      </div>

      <div class="contact-info">
        <img src="../assets/icons/mail.svg"  alt="mail icon">
        <div class="contact-text">
          <p>Email us</p>
          <p>Speak to our friendly team</p>
          <a href="mailto:info@motorgleam.co.uk">info@motorgleam.co.uk</a>
        </div>
      </div>

      <div class="contact-info">
        <img src="../assets/icons/instagram.png" alt="instagram icon">
        <div class="contact-text">
          <p>Instagram us</p>
          <p>View our latest work</p>
          <a href="https://www.instagram.com/motorgleamvaleting/">@motorgleamvaleting</a>
        </div>
      </div>

      <div class="contact-info">
        <img src="../assets/icons/facebook.svg" alt="facebook icon">
        <div class="contact-text">
          <p>Facebook us</p>
          <p>Like our Facebook page</p>
          <a href="https://www.facebook.com/motorgleamvaleting">@motorgleamvaleting</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>


</script>

<style lang="scss" scoped>
.contact-info-section {
  margin-top: 80px;

  .contact-info-container {
    width: 80%;
    margin: 0 auto;
    img {
      width: 68px;
    }

    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;

    .contact-info {
      display: flex;
      gap: 20px;
      width: 300px;

      .icon {
        border-radius: 22px;
        width: 68px;
        height: 68px;
        background-image: url('/src/assets/cars/gradient.svg');
      }

      .contact-text {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin: 0;

        p {
          margin: 1px;
        }
      }
    }
  }
}



</style>