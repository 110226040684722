<template>
  <div class="container">

    <AboutUs></AboutUs>
    <CleaningServices></CleaningServices>
    <ContactUs></ContactUs>
    <PhotoGallery></PhotoGallery>
    <RecentReviews></RecentReviews>

  </div>
</template>

<script setup>

import {ref} from "vue";
import CleaningServices from "@/components/CleaningServices";
import RecentReviews from "@/components/RecentReviews";
import ContactUs from "@/components/ContactUs";
import PhotoGallery from "@/components/PhotoGallery";
import AboutUs from "@/components/AboutUs.vue";

const isMobile = ref(false);

window.addEventListener('resize', () => {
  isMobile.value = window.innerWidth < 700;
})

</script>

<style lang="scss" scoped>

.container {
  color: white;
  margin: 0 auto;

  .welcome {
    padding-bottom: 10px;

    .welcome-header {
      letter-spacing: -2px;
    }

    .welcome-container {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      position: relative;

      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 18px;
        margin-top: 40px;
        margin-bottom: 70px;
      }
    }
  }
}
.logo {
  margin-top: 20px;
  width: 300px;
}
</style>