<template>
  <div class="maintenance-section">
    <h2 id="contact"><span class="underline-heading--teal">Maintenance</span> Service</h2>

    <div class="heading">
      <p><b>Our maintenance package is tailored to suit individuals who want to maintain the cleanliness of their vehicle on
        a
        fortnightly, monthly, or quarterly basis. Kindly note that an initial <a href="deepclean">Deep Clean</a> is prerequisite for subscribing to our maintenance services. Additionally, if the vehicle goes without cleaning for six weeks or more, it will necessitate another Deep Clean to ensure the highest standards of cleanliness and care are met</b></p>

    </div>

    <div class="maintenance-container">
      <img src="../assets/cars/maintenance.jpeg">
      <div class="description">
        <div class="prices-container">
          <p><b>Duration: up to 120 minutes</b></p>
          <div class="prices">
            <div class="price">
              <p><b>Small</b></p>
              <p><span>£50</span></p>
            </div>

            <div class="price">
              <p><b>Medium</b></p>
              <p><span>£60</span></p>
            </div>
            <div class="price">
              <p><b>Large</b></p>
              <p><span>£75</span></p>
            </div>
          </div>
        </div>

        <ul>
          <li>Wheels deep cleaned, including wheel arches</li>
          <li>Non-caustic pre-wash sprayed onto vehicle and cleaned off with fresh water</li>
          <li>Vehicle coated with snow foam</li>
          <li>Hand-wash bodywork (two-bucket method) with lambswool mitt and shampoo</li>
          <li>Rinse and apply superhydrophobic coating (a protective coating which causes surface water to bead on
            vehicle)
          </li>
          <li>Rinse and blow off any excess water if needed</li>
          <li>Dry exterior with large soft microfibre towels</li>
          <li>Finish all windows and mirrors with glass cleaner and cloth</li>
          <li>Dress tires</li>
          <li>Wipe down door and boot shuts</li>
          <li>Vacuum interior</li>
          <li>Dash and console dusted</li>
          <li>Rubber door seals cleaned</li>
          <li>Thorough dashboard clean</li>
          <li>Doors and door pockets wiped</li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script setup>


</script>

<style lang="scss" scoped>

.maintenance-section {
  //height: 100vh;
  background: linear-gradient(to bottom, black, #5E6472);
  color: white;
  text-align: left;

  h2 {
    text-align: center;
  }

  .heading {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    width: 80%;
  }
}

.maintenance-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;


  gap: 30px;
}

.description {
  max-width: 500px;
  line-height: 26px;
}


b {
  max-width: 950px;
  margin: 0 auto;
}

img {
  max-width: 600px;
  width: 100%;
}

li::marker {
  color: #2ed7cf;
}

.prices-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  .prices {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    span {
      color: #2ed7cf;
    }
  }
  p {
    padding: 0;
  }
}


</style>