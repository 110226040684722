import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/views/HomePage";
import MaintenanceService from "@/views/MaintenanceService"
import FullworksService from "@/views/FullworksService"

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: MaintenanceService,
    },
    {
        path: "/deepclean",
        name: "Deep Clean",
        component: FullworksService,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;