<template>
  <div class="services-section">
    <div class="services-container">
      <h2 id="services">Our <span class="underline-heading--teal">Services</span></h2>

      <div class="services">
        <div class="services-text">
          <h3>Comprehensive Mobile Valeting Services</h3>
          <p class="long-text">Our mobile valeting services cover all aspects of the trade, providing comprehensive coverage. We offer an extensive range of services, from basic washes to full detailing packages.</p>
        </div>

        <Carousel :wrap-around="true" :transition="500"  :items-to-show="1" :autoplay="3000">
          <Slide v-for="slide in slides" :key="slide.id" aria-label="slide showing different services">
            <div class="carousel__item">
              <div class="service" :class="slide.service">
                <div class="service-text">
                  <span>{{ slide.title }}</span>
                  <p>{{ slide.content }}</p>
                </div>
                <button class="button button--secondary" aria-label="click for more info" @click="goToService(slide.service)">More info</button>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Pagination/>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script setup>

import {Carousel, Pagination, Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import router from "@/router";
// const scrollTo = function(id) {
//   document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
// }

const slides = [
  {
    id: 1,
    title: 'Maintenance',
    service: 'maintenance',
    content: 'Maintenance and vehicle sanitisation valet. Duration up to 120 minutes, starts from £50',
    image: '/src/assets/cars/maintenance.jpeg'
  },
  {
    id: 2,
    title: 'Deep Clean',
    service: 'deepclean',
    content: 'Vehicle Sanitisation Valet Duration up to 240 minutes, starts from £125',
    image: '/src/assets/cars/maintenance.jpeg'
  },
]


function goToService(service) {
  router.push(`/${service}`);
}
</script>

<style lang="scss">

h3 {
  font-size: 2rem;
}

.services-section {
  background: linear-gradient(to bottom, black, #5E6472);
  //background: #5E6472;
  .services-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;

    .services {
      padding: 20px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      @media only screen and (min-width: 601px) {
        .long-text {
          max-width: 600px;
          text-align: start;
          margin: 1px;
        }

        .services-text {
          margin-bottom: 50px;
        }
      }



      @media only screen and (max-width: 600px) {
        h3 {
          display: none;
        }
      }


      .carousel {
        width: 500px;
      }

      .service {
        width: 309px;
        height: 469px;
        border-radius: 50px;
        position: relative;
        background-image: url('/src/assets/cars/gradient.svg');

        .service-text {
          color: black;
          display: flex;
          flex-direction: column;
          align-items: start;
          padding: 20px;

          span {
            font-family: 'Roboto', sans-serif;
            font-size: 1.8em;
            font-weight: 500;
          }

          position: absolute;
          top: 235px;
          text-align: start;
        }

        .button {
          position: relative;
          top: 380px;
          left: 70px;
          width: 120px;
          cursor: pointer;
          font-size: 1rem;
          color: white;

        }
      }
      .maintenance:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 50%;
        background-image: url('/src/assets/cars/maintenance.jpeg');
        background-size: cover;
        background-position: center;
        border-radius: 50px 50px 0 0;
      }

      .deepclean:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 50%;
        background-image: url('/src/assets/cars/deepclean.jpeg');
        background-size: cover;
        background-position: center;
        border-radius: 50px 50px 0 0;
      }
    }
  }
}


.carousel__item {
  min-height: 200px;
  width: 100%;
  //background-color: var(--vc-clr-primary);
  //color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  color: #176962;
  background: transparent;
}

.carousel__pagination-button::after {
  background: #ffffff;
  //padding: 2px;
}

.carousel__pagination-button--active::after {
  background: #28A99E;
}

.carousel__pagination {
  padding: 0;
}


.carousel__pagination-button {
  &:hover {
    background: #28A99E;
  }
}


</style>