<template>
  <div class="reviews-section">
    <div class="google-reviews-container">
      <h2 id="reviews">Gleam <span class="underline-heading--teal">Gallery</span></h2>
      <Carousel :wrap-around="true" :transition="500"  :items-to-show="shouldShowLessReviews ? 1 : 3">
        <Slide v-for="slide in slides" :key="slide.id">
          <div class="carousel__item">
            <img class="photo" alt="image of reviewer" :src="slide.imageUrl" @click="selectedImage = slide.imageUrl"/>
          </div>
        </Slide>
        <template #addons>
          <Pagination/>
        </template>
      </Carousel>
    </div>
<!--    <div v-if="selectedImage" class="maximized-image-container">-->
<!--      <img class="maximized-image" :src="selectedImage" @click="selectedImage = null"/>-->
<!--    </div>-->
  </div>
</template>

<script setup>
import {Carousel, Pagination, Slide} from 'vue3-carousel'
import {computed, onMounted, ref} from "vue";

const baseUrl = 'https://motorgleamapp.s3.eu-west-2.amazonaws.com';
let slides = ref([]);
onMounted(async () => {
  try {
    const res = await fetch(baseUrl);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(await res.text(), "application/xml");

    const keys = xmlDoc.getElementsByTagName("Key");
    let allKeys = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i].textContent;
      allKeys.push(key);
    }
    allKeys = allKeys.sort((a, b) => a.split(".") - b.split("."));
    slides.value = allKeys.map((key, index) => ({
      id: index,
      imageUrl: baseUrl + "/" + key
    }));
  } catch (error) {
    console.error('An error occurred:', error);
  }
});
const windowWidth = ref(window.innerWidth);
const shouldShowLessReviews = computed(() => windowWidth.value <= 1000);
const selectedImage = ref(null);

window.addEventListener('resize', () => {
  windowWidth.value = window.innerWidth;
})

document.addEventListener('click', (event) => {
  if (event.target.closest('.maximized-image-container')) {
    selectedImage.value = null;
  }
});

</script>

<style lang="scss" scoped>
.reviews-section {
  margin-top: 80px;
}

.photo {
  height: 500px;
  //width: 500px;
  cursor: pointer;
}

.maximized-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.maximized-image {
  max-width: 90%;
  max-height: 90%;
}
</style>