<template>
  <div class="about-info-section">
    <h2 id="about">About <span class="underline-heading--teal">Us</span></h2>
    <div class="about-info-container">
      <div class="story">
        <p>
          After working for numerous companies in the valeting and detailing industry over the last 4 years, I decided to
          start motorgleam as I felt there was gap in the market for a service that offers high level of customer care and
          strives for perfection without cutting corners.
        </p>
        <p>
          I have always had a passion for cars and spent countless hours cleaning my own vehicles. In 2023 I decided to
          pursue this passion and turn it into a luxury mobile car care business covering mainly the North West Norfolk
          but our radius has only grown due to building a solid reputation for consistency and diligence within this
          field.
        </p>
        <p>
          Although we do detail and maintain many luxury cars, motorgleam is geared towards absolutely anyone no matter
          the vehicle. From Ferraris to Ford Fiestas, every detail is treated in exactly the same professional manner.
        </p>

        <p>
          Our fully mobile setup is stocked with the best eco friendly products on the market ensure your vehicle is
          looked after in the best possible way.
        </p>
      </div>
      <div class="van">

        <img src="../assets/cars/van.jpeg">
      </div>
    </div>
  </div>

</template>

<script setup>


</script>

<style lang="scss" scoped>
h2 {
  padding-top: 0;
}
.about-info-section {
  margin-top: 80px;

  .about-info-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    text-align: left;
    justify-content: center;
    gap: 10px;

    .van {
      img {
        height: 500px;
      }
    }
    .story {
      width: 500px;

    }
  }
}


</style>
