<template>
  <div class="fullworks-section">
    <h2 id="contact"><span class="underline-heading--teal">Deep Clean </span>Service</h2>
    <div class="heading">
      <p><b>The full works package is tailored to suit individuals who want their car back to a showroom condition for personal enjoyment or are looking to sell the vehicle and want it looking the best it can be. </b></p>
    </div>
    <div class="fullworks-container">
      <img src="../assets/cars/audi.jpeg">
      <div class="description">

        <div class="prices-container">
          <p><b>Duration: up to 360 minutes</b></p>
          <div class="prices">
            <div class="price">
              <p><b>Small</b></p>
              <p><span>from £100</span></p>
            </div>

            <div class="price">
              <p><b>Medium</b></p>
              <p><span>from £150</span></p>
            </div>
            <div class="price">
              <p><b>Large</b></p>
              <p><span>from £200</span></p>
            </div>
          </div>
        </div>
        <ul>
          <li>Wheels deep cleaned, including wheel arches</li>
          <li>Non Caustic Pre wash sprayed onto vehicle and cleaned off with fresh water</li>
          <li>Vehicle coated with snow foam and badges and trims cleaned with soft brush</li>
          <li>Rinse Snow Foam</li>
          <li>Hand wash bodywork (Two bucket method) with lambs wool mitt and shampoo</li>
          <li>Apply fallout remover/ tar remover and rinse</li>
          <li>Snow foam Vehicle Again and clean with lamb wool mitt</li>
          <li>Rinse and apply a thicker layer of Super hydrophobic coating (a protective coating which cause surface water to bead on vehicle)</li>
          <li>Rinse and blow off an excess water if needed</li>
          <li>Dry exterior with large soft microfibre towels</li>
          <li>Apply a quick detailer onto vehicle</li>
          <li>Finish all windows and mirrors with glass cleaner and cloth</li>
          <li>Dress tyres</li>
          <li>Wipe down door and boot shuts</li>
          <li>Vacuum interior</li>
          <li>Shampoo interior</li>
          <li>Steam interior</li>
          <li>Shampoo Seats</li>
          <li>Dash and console dusted</li>
          <li>Dress internal and external rubber/plastic (non greasy finish)</li>
          <li>Rubber door seals cleaned</li>
          <li>Thorough dashboard clean</li>
          <li>Doors and door pockets wiped</li>
          <li>Eliminate any odors</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>


</script>

<style lang="scss" scoped>

.fullworks-section {
  //height: 100vh;
  background: linear-gradient(to bottom, black, #5E6472);
  color: white;
  text-align: left;

  h2 {
    text-align: center;
  }

  .heading {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    width: 80%;
  }
}

.fullworks-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;


  gap: 30px;
}

.description {
  max-width: 500px;
  line-height: 26px;
}


b {
  max-width: 950px;
  margin: 0 auto;
}

img {
  max-width: 600px;
  width: 100%;
}

li::marker {
  color: #2ed7cf;
}

.prices-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  .prices {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    span {
      color: #2ed7cf;
    }
  }
  p {
    padding: 0;
  }
}

</style>