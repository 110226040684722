<template>
  <div class="header-container">
    <div class="nav-bar">
      <a class="nav-link" @click="router.push('/')">Home</a>
      <a class="nav-link" @click="scrollTo('about')">About</a>
      <a class="nav-link" @click="scrollTo('services')">Services</a>
      <a class="nav-link" @click="scrollTo('contact')">Contact</a>
      <a class="nav-link" @click="scrollTo('reviews')">Reviews</a>

      <!--      <button class="button button&#45;&#45;secondary" @click="scrollTo('contact')">Contact us</button>-->
    </div>
    <!--    <div class="header">-->
    <!--      <img class="header-image" alt="Vue logo" src="./assets/logo.png">-->
    <!--    </div>-->

    <div class="slogan">
      <h1>
        Showroom Shine,
      </h1>

      <h1>
        Every Time.
      </h1>
    </div>

  </div>

  <router-view class="router"></router-view>
</template>

<script setup>

import router from "@/router";

const scrollTo = function (id) {
  router.push('/');
  if (window.location.pathname !== '/') {
    router.push('/').then(() => {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({behavior: 'smooth'});
        }
      }, 0);
    }).catch(error => {
      console.error("Failed to navigate: ", error);
    });
  } else {
    document.getElementById(id).scrollIntoView({behavior: 'smooth'});
  }
}

</script>

<style lang="scss">
html, body {
  background: black;
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

h2 {
  margin: 0;
  padding: 53px 13px 40px 13px;
  color: white;
}

.button {
  padding: 10px;
  border-radius: 5px;
  color: white;

  &--primary {
    background: #28A99E;

    &:hover {
      background-color: #2ed7cf;
    }
  }

  &--secondary {
    background: black;
    color: white;
    border: 1px white solid;

    &:hover {
      background-color: #313131;
    }
  }

  font-weight: 700;
  color: black;
  font-size: 1em;
  text-decoration: none;
  border: none;
  cursor: pointer;
  position: relative;
  width: 200px;
  height: 55px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.underline-heading--black {
  text-decoration: underline;

  text-decoration-thickness: 6px;
  border-radius: 40px; /* adjust as needed */
  text-underline-offset: 20px;
  text-decoration-color: #000000;
}

.underline-heading--teal {
  text-decoration: underline;

  text-decoration-thickness: 6px;
  border-radius: 40px; /* adjust as needed */
  text-underline-offset: 20px;
  text-decoration-color: #28A99E;
}

@font-face {
  font-family: 'hartwell';
  src: url('/src/assets/fonts/Hartwell Semibold.otf');
  font-display: swap;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: black;
  color: #2c3e50;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.575;
}

.long-text {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;

  &.intro {
    padding-top: 20px;
  }
}

a {
  color: #9cd0cc;
}


@media only screen and (max-width: 600px) {
  h2 {
    font-size: 3em; /* Set the font size for mobile devices */
  }
}

@media only screen and (min-width: 601px) {
  h2 {
    font-size: 4em; /* Set the font size for non-mobile devices */
  }
  .slogan {
    position: relative;
    //width: 80%; /* Example width - adjust as needed */
    //max-width: 1200px; /* Maximum width of the slogan container */
    margin: 0 auto; /* Centers the block horizontally */
    margin-top: 10%;
    //margin-left: 50px;

    h1 {
      text-align: center;
      font-size: 3em;
    }
  }
}

.nav-bar {

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 60px;

  a {
    color: white;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    text-underline-offset: 10px;
    text-decoration-color: #28A99E;
  }
}

.nav-link.router-link-active {
  text-decoration: underline;

}

.header-container {
  background-image: url("/src/assets/cars/background-car.png");
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: left bottom;
  min-height: 100vh;
  position: relative;
  //display: flex;

  .header {
    font-family: "hartwell", serif;
    //background: #5E6472;
    //padding-top: 60px;


    .header-image {
      //width: 50%;
      width: 300px;
      margin: 0 auto;
    }
  }

  .slogan {
    font-size: 1.8em;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: left;

    margin-left: 20px;
    margin-top: 10%;

    h1 {
      padding: 0;
      margin: 0;
    }
  }
}

.router {
  padding-bottom: 200px;
}
</style>
