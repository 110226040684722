<template>
  <div class="reviews-section">
    <div class="google-reviews-container">
      <h2 id="reviews">Recent <span class="underline-heading--teal">Reviews</span></h2>
      <p>Check out our <a href="https://www.google.com/search?q=motorgleam+google&rlz=1C1CHBF_enGB1053GB1053&oq=motorgleam+google&gs_lcrp=EgZjaHJvbWUyCwgAEEUYChg5GKABMgkIARAhGAoYoAEyCQgCECEYChigATIJCAMQIRgKGKAB0gEIMjc4NGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x898749cbf517d77f:0x869bce8eb11038be,1,,,,">Google reviews</a></p>
      <Carousel :wrap-around="true" :transition="500" :autoplay="3000" :items-to-show="shouldShowLessReviews ? 1 : 3">
        <Slide v-for="slide in slides" :key="slide.id">
          <div class="carousel__item">
            <div class="review">

              <div>
                <img class="profile-photo" alt="image of reviewer" :src="getImgUrl(slide.image)"/>
              </div>

              <div>
                <p>{{ getContent(slide.content) }}</p>
              </div>

              <img class="google-icon" alt="google icon" src="../assets/icons/google-icon.png"/>
              <!--              <button class="button button&#45;&#45;secondary">More info</button>-->
            </div>
          </div>
        </Slide>
        <template #addons>
          <Pagination/>
        </template>
      </Carousel>
    </div>
  </div>

</template>

<script setup>
import {Carousel, Pagination, Slide} from 'vue3-carousel'
import {computed, ref} from "vue";
const windowWidth = ref(window.innerWidth);
const shouldShowLessReviews = computed(() => windowWidth.value <= 1000);

window.addEventListener('resize', () => {
  windowWidth.value = window.innerWidth;
})

const slides = [
  {
    id: 1,
    title: 'Express',
    class: 'express',
    content: 'Absolutely brilliant, feels like I’m stepping into a new car! And it’s been 3 weeks since Jack cleaned it. Can’t recommend enough.',
    image: 'review1.jpg'
  },
  {
    id: 2,
    title: 'Premium',
    class: 'premium',
    content: 'The team at Motor Gleam did a fantastic job on my car. It\'s been over a month and it still looks as good as new. Highly recommend their services!',
    image: 'review2.jpg'
  },
  {
    id: 3,
    title: 'Fullworks',
    class: 'fullworks',
    content: 'I was blown away by the level of detail that Motor Gleam put into cleaning my car. It\'s never looked better. If you want your car to sparkle, give them a try.',
    image: 'review3.jpg'
  },
  {
    id: 4,
    title: 'Fullworks',
    class: 'fullworks',
    content: 'I\'ve been to a few valeting companies in the past, but this one takes the cake. Their attention to detail is unmatched and my car looked and smelled amazing after they were done. Will definitely be a repeat customer.',
    image: 'review4.jpg'
  },
  {
    id: 5,
    title: 'Fullworks',
    class: 'fullworks',
    content: 'If you\'re looking for a valeting company that truly cares about making your car look its best, look no further. The team here did an amazing job and went above and beyond my expectations. I\'m already planning my next visit.',
    image: 'review5.jpg'
  },
]


function getContent(content)
{
  if (content.length > 160) {
    return content.substring(0, 160).trim() + "..."
  }
  return content;
}

function getImgUrl(pic) {
  return require('../assets/reviews/'+pic)
}

</script>

<style lang="scss" scoped>

.reviews-section {
  margin-top: 80px;
  .google-reviews-container {
    width: 80%;
    margin: 0 auto;

  }
}

.review {
  width: 288px;
  min-width: 288px;
  height: 360px;
  background: white;
  border-radius: 26px;

  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  position: relative;


  .profile-photo {
    width: 120px;
    border-radius: 50%;

    //position: absolute;
    margin-top: 20px;
  }

  p {
    color: black;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    //margin-top: 100px;
    //position: absolute
  }


  .google-icon {
    position: absolute;
    width: 30px;
    bottom: 10px;
  }
}


</style>